.profile__add-btn {
  background-image: url(../../../images/add-button.svg);
  background-repeat: no-repeat;
  background-size: initial;
  background-position: center;
  cursor: pointer;
  border: 2px #ffffff solid;
  border-radius: 2px;
  width: 150px;
  height: 50px;
  background-color: transparent;
  margin: 35px 0;
  padding: 0;
  outline: none;
  transition: opacity 0.3s ease 0.1s;
}

.profile__add-btn:hover {
  opacity: 0.6;
}

@media screen and (max-width: 500px) {
  .profile__add-btn {
    width: 100%;
    margin: 32px 0;
  }
}
