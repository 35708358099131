@import url(./__copyright/footer__copyright.css);

.footer {
  margin: 66px auto 60px auto;
  width: calc(882 / 1280 * 100%);
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .footer {
    width: calc(282 / 320 * 100%);
    margin: 45px auto 45px auto;
  }
}
