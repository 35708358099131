.card__name {
  color: #000000;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  max-width: 80%;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
