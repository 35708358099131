.auth__send {
  font-weight: normal;
  font-size: 18px;
  line-height: 1.21;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  width: 100%;
  height: 50px;
  margin: 204px 0 0 0;
  padding: 0;
  outline: none;
  transition: opacity 0.3s ease 0.1s;
}

.auth__send:hover {
  opacity: 0.8;
}

@media screen and (max-width: 550px) {
  .auth__send {
    margin-top: 160px;
    font-size: 16px;
  }
}
