.footer__copyright {
  color: #545454;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  margin: 0;
}

@media screen and (max-width: 500px) {
  .footer__copyright {
    font-size: 14px;
  }
}
