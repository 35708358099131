.card__like {
  background-image: url(../../../images/like-button.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 21px;
  height: 19px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin: auto 0;
  padding: 0;
  outline: none;
  transition: opacity 0.3s ease 0.1s;
}

.card__like:hover {
  opacity: 0.6;
}
