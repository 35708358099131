.auth__input {
  border: none;
  width: 100%;
  margin: 9px 0;
  padding: 12px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.21;
  background-color: rgb(0, 0, 0);
  color: #ffffff;
  border-bottom: 2px solid rgba(204, 204, 204, 1);
  outline: none;
}
