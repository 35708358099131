.profile__edit-avatar {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 45%;
  background-repeat: no-repeat;
  background-image: none;
  z-index: 2;
  transition: background 0.3s ease 0.1s;
  background-image: none;
  background-color: rgba(0, 0, 0, 0);
}
