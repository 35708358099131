.profile__avatar {
  border-radius: 50%;
  max-width: 120px;
  max-height: 120px;
  height: 120px;
  width: 100%;
  margin: 0 30px 0 0;
  z-index: 1;
  object-fit: cover;
}

@media screen and (max-width: 950px) {
  .profile__avatar {
    margin: 0;
  }
}
