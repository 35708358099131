.profile__photo-group {
  margin: 0;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 16px 0 0;
  padding: 0;
  position: relative;
}

.profile__photo-group:hover .profile__edit-avatar {
  background-image: url(../../../images/edit-big-button.svg);
  background-color: rgba(0, 0, 0, 0.8);
  background-position: 50% 50%;
  cursor: pointer;
}
