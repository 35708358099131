.profile__group {
  width: 80%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 950px) {
  .profile__group {
    text-align: center;
    flex-direction: column;
  }
}
