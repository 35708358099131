.profile__edit-btn {
  background-image: url(../../../images/edit-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  cursor: pointer;
  border: 1px #ffffff solid;
  width: 24px;
  height: 24px;
  margin: auto 0 auto 19px;
  padding: 0;
  outline: none;
  transition: opacity 0.3s ease 0.1s;
}

.profile__edit-btn:hover {
  opacity: 0.6;
}

@media screen and (max-width: 500px) {
  .profile__edit-btn {
    width: 18px;
    height: 18px;
    margin-left: 6px;
  }
}
