@import url(./__info/profile__info.css);
@import url(./__row/profile__row.css);
@import url(./__group/profile__group.css);
@import url(./__photo-group/profile__photo-group.css);
@import url(./__avatar/profile__avatar.css);
@import url(./__edit-avatar/profile__edit-avatar.css);
@import url(./__name/profile__name.css);
@import url(./__profession/profile__profession.css);
@import url(./__edit-btn/profile__edit-btn.css);
@import url(./__add-btn/profile__add-btn.css);
.profile {
  display: flex;
  margin: 0;
  flex-direction: row;
  padding: 40px 0 51px 0;
  justify-content: space-between;
}

@media screen and (max-width: 950px) {
  .profile {
    flex-direction: column;
    align-items: center;
    padding-bottom: 3px;
  }
}
