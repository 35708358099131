.content {
  margin: auto;
  width: calc(882 / 1280 * 100%);
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 500px) {
  .content {
    width: calc(282 / 320 * 100%);
  }
}
