.auth__link {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: #ffffff;
  transition: opacity 0.5s ease 0.1s;
}
.auth__link:hover {
  opacity: 0.6;
}
