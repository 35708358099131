.logo {
  width: 142px;
  height: 34px;
}

@media screen and (max-width: 500px) {
  .logo {
    width: 104px;
    height: 25px;
  }
}
