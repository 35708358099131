.header__button {
  border: 0;
  padding: 0;
  margin: 0 0 0 24px;
  background: none;
  color: #a9a9a9;
  cursor: pointer;
  outline: none;
  transition: opacity 0.3s ease 0.1s;
}
.header__button:hover {
  opacity: 0.6;
}

@media screen and (max-width: 550px) {
  .header__button {
    font-size: 14px;
    line-height: 1.21;
    margin-right: 30px;
  }
}
