.popup__container {
  width: 430px;
  background-color: #ffffff;
  margin: auto;
  padding: 30px 0;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 550px) {
  .popup__container {
    width: 250px;
  }
}
