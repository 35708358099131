.auth__title {
  font-weight: 900;
  font-size: 24px;
  line-height: 1.21;
  margin: 60px 0 26px;
}

@media screen and (max-width: 550px) {
  .auth__title {
    font-size: 20px;
    margin: 40px 0 18px;
  }
}
