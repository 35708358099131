@keyframes show {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: flex;
  }
  100% {
    display: flex;
    opacity: 1;
  }
}

.popup_opened {
  display: flex;
  opacity: 1;
  animation: show 500ms linear;
  animation-fill-mode: forwards;
}
