.profile__name {
  max-width: calc(100% - 50px);
  margin: 0 0 5px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  line-height: 1.33;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .profile__name {
    font-size: 27px;
    line-height: 2.07;
    margin: 0;
  }
}
