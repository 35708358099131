.popup__title {
  color: #000000;
  font-weight: 900;
  font-size: 24px;
  line-height: 1.2;
  margin: 16px 0;
  text-align: center;
}
@media screen and (max-width: 550px) {
  .popup__title {
    font-size: 14px;
  }
}
