.popup__input {
  border: none;
  width: 100%;
  margin: 9px 0;
  padding: 12px 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.21;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
}
.popup__input:first-of-type {
  margin-top: 20px;
}
@media screen and (max-width: 550px) {
  .popup__input {
    font-size: 12px;
  }
  .popup__input:first-of-type {
    margin-top: 0;
  }
}
