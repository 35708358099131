.popup__save {
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  border: none;
  border-radius: 2px;
  width: 100%;
  height: 50px;
  margin: 36px 0 0 0;
  padding: 0;
  outline: none;
  transition: opacity 0.3s ease 0.1s;
}

.popup__save:hover {
  opacity: 0.8;
}

@media screen and (max-width: 550px) {
  .popup__save {
    margin: 0;
    font-size: 14px;
  }
}
