.profile__profession {
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.22;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 500px) {
  .profile__profession {
    font-size: 14px;
    line-height: 1.21;
  }
}
